import {DialogController} from 'aurelia-dialog';
import {inject} from "aurelia-framework";

@inject(
    DialogController
)
export class PreviewModal
{
    constructor(
        dialogController
    ) {
        this.dialogController = dialogController;
    }

    async activate(context) {
        this.file = context.file;

        this.url = this.file.previewUrl.replace('$width', '-').replace('$height', '-');
    }
}