import {bindable, containerless, customElement, inject} from "aurelia-framework";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import {UserClient} from "../api/user-client";

const START = 0x1F1E6 /* REGIONAL INDICATOR SYMBOL LETTER A */ - 0x41 /* LATIN CAPITAL LETTER A */;

@inject(UserClient)
@containerless() @customElement("phone-display")
export class PhoneDisplay {

    @bindable value;

    constructor(user) {
        this.user = user;
    }

    bind() {
        this.country = null;
        this.number = this.value;
        if (this.value) {
            this.tel = 'tel:' + this.value.replaceAll('-', '');
        }

        const userCountry = this.user.getCountry();

        try {
            const parsed = parsePhoneNumberFromString(this.value, userCountry);
            const country = parsed && parsed.country;

            if (!country) {
                return;
            }

            if (this.user.getUser().settings?.userSettings?.showLocalNumber && country === userCountry) {
                this.number = parsed.formatNational();
            } else {
                this.country = country;
                this.emoji = String.fromCodePoint(START + country.charCodeAt(0), START + country.charCodeAt(1));
            }

        } catch (e) {
            console.error(e);
        }
    }
}
