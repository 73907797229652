import {bindable, customElement} from 'aurelia-framework';

import "./popover.less";

@customElement('popover')
export class Popover {
    @bindable content;
    @bindable iconClass = "glyphicon glyphicon-question-sign";

    attached() {
        $(this.popover).popover({
            //Bugfix for popover not dynamically updated
            content: () => {
                return this.content
            },
            container: 'body',
            placement: 'auto right',
            trigger: 'hover',
            html: true,
            sanitize: false,
        });
    }

}
